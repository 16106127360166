import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { MultiSelect } from "react-multi-select-component";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import 'moment/locale/en-gb';
import { RetailTrainingService } from "../services/Services"
import CustomToolbar from "./CustomToolbar"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, Typography } from "@mui/material";
import { UseMessage } from './Alert';
import { RWebShare } from "react-web-share";
import Multiselect from 'multiselect-react-dropdown';
import QRCode from 'react-qr-code'
export default function CenterComponent(props) {

    const [selectedItem, setSelectedItem] = React.useState('calendar')
    const [open, setOpenModal] = React.useState(false)
    const [usersList, setUsersList] = React.useState([])
    const [usersListSelected, setUsersListSelected] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [loadingFilters, setLoadingFilters] = React.useState(false)
    const [eventsList, setEventsList] = React.useState([])
    const [allEvents, setAllEvents] = React.useState([])
    const [allEventsCount, setAllEventsCount] = React.useState([])
    const [selectedEvent, setSelectedEvent] = React.useState([])
    const [eventDetail, setEventDetail] = React.useState(false)
    const [eventDetailSubMenu, setEventDetailSubMenu] = React.useState(false)
    const [list_has_more, setListHasMore] = React.useState(false)
    const [lastTo, setLastTo] = React.useState(null)
    const [lastFrom, setLastFrom] = React.useState(null)
    const [nextEventDate, setNextEventDate] = React.useState(null)
    const [prevEventDate, setPrevEventDate] = React.useState(null)
    const [firstTimePrev, setFirstTimePrev] = React.useState(true)
    const [list_has_prev, setListHasPrev] = React.useState(false)
    const [date] = React.useState(new Date())
    const [selectedDate, setSelectedDate] = React.useState(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
    // const [from_date, setFromDate] = React.useState(new Date(date.getFullYear(), date.getMonth(), 1))
    const [from_date, setFromDate] = React.useState(new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0])
    //const [to_date, setToDate] = React.useState(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    const [to_date, setToDate] = React.useState(new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0])
    const [searchText, setSearchText] = React.useState('')
    const [dateString, setDateString] = React.useState('Today')
    const [rtS] = React.useState(new RetailTrainingService())
    const [user, setUser] = React.useState(null)
    const [monthValues, setMonthValues] = React.useState([])
    const [confirmDisabled, setConfirmDisabled] = React.useState(false)
    const [openFollowerModal, setFollowerModalStatus] = React.useState(false)
    const [textToCopy, setTextCopy] = React.useState('')
    const [visibleShare, setVisibleShare] = React.useState(false)

    const [selectedChannels, setSelectedChannels] = React.useState([])
    const [selectedTargets, setSelectedTargets] = React.useState(props.preselectedTargets ? props.preselectedTargets : [])
    const [apply, setApply] = React.useState([false])
    const [qrTab, setQrTab] = React.useState(false)
    const [ellipsis, setEllipsis] = React.useState(true)

    useEffect(() => {
        if (props.eventIDValue) {
            getEventByIDValue(props.eventIDValue)

        }
        setVisibleShare(false)



    }, [])

    /* useEffect(() => {
         let selectedtg = selectedTargets
         if (user && user.is_luce_user) {
             if (user.default_target_ids && user.default_target_ids.length > 0 && props.filters && props.filters.targets && props.filters.targets.length > 0)
                 user.default_target_ids.map((o) => {
                     console.log(o)
                     if (props.filters && props.filters.targets.length > 0)
                         props.filters.targets.map((t) => {
                             if (t.id == o) {
                                 selectedtg.push({
                                     id: o, name: t.name
                                 })
                             }
                         })
                 }
                 )
             console.log(selectedtg)
             setSelectedTargets(selectedtg)
             //   console.log(props.filters)
             // selectFilter([], 'targets')
         }
     }, [user])
 */
    useEffect(() => {
        setTimeout(() => {

            if (document.getElementsByClassName('searchBox ') && document.getElementsByClassName('searchBox ').length > 0) {
                let a = [...document.getElementsByClassName('searchBox ')]
                a.map(element => {
                    element.setAttribute('readonly', '')
                });
            }
        }, 500);
    }, [props.filtersTab])

    useEffect(() => {
        if (!apply) {
            setSelectedChannels([])
            setSelectedTargets([])
            //setSelectedDate(new Date(sele.getFullYear(), date.getMonth(), date.getDate()))
        }
    }, [props.filtersTab])


    useEffect(() => {
        let tmpselected = new Date(selectedDate)
        setLoading(true)

        if (selectedItem === 'calendar')
            getEventsByDay(new Date().toISOString().split('T')[0])
        else
            getListItems()

        if (new Date(from_date).getMonth() == new Date(selectedDate).getMonth())
            getEvents('', from_date, to_date)
        else
            getEvents('', new Date(tmpselected.getFullYear(), tmpselected.getMonth(), 1), new Date(tmpselected.getFullYear(), tmpselected.getMonth() + 1, 0))

        setEventDetailSubMenu(false)
        props.setShowFilter(true)

        rtS.getUser().then((res) => {
            setUser(res)
        })
    }, [props.isWishlistView])

    const changeSelection = (value) => {
        setLoading(true)
        let channels = []
        selectedChannels.map((c) => {
            channels.push(c.id)
        })
        let targets = []
        selectedTargets.map((t) => {
            targets.push(t.id)
        })
        let today = new Date()
        let from = new Date(today.getFullYear(), today.getMonth(), 1)
        //per gestire il +1/+2 gmt
        from.setHours(3)



        if (value === 'calendar') {
            let options = {
                "search_text": '',
                "from_date": from,
                "to_date": from,
                "search_target_ids": targets,
                "search_channel_ids": channels
            }

            rtS.getEvents(options).then((res) => {
                setEventsList(res.events)
                setSelectedItem(value)
                setLoading(false)
            })
        }
        else
            getListItems()

    }

    const getEventsByDay = (day) => {
        setLoading(true)
        let channels = []
        selectedChannels.map((c) => {
            channels.push(c.id)
        })
        let targets = []
        selectedTargets.map((t) => {
            targets.push(t.id)
        })
        let options = {
            "search_text": '',
            "from_date": day,
            "to_date": day,
            "search_target_ids": targets,
            "search_channel_ids": channels
        }

        rtS.getEvents(options).then((res) => {
            setEventsList(res.events)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }
    const resetEventsByDay = (day) => {
        setLoading(true)
        let channels = []
        selectedChannels.map((c) => {
            channels.push(c.id)
        })
        let targets = []
        selectedTargets.map((t) => {
            targets.push(t.id)
        })
        let options = {
            "search_text": '',
            "from_date": day,
            "to_date": day,
            "search_target_ids": [],
            "search_channel_ids": []
        }

        rtS.getEvents(options).then((res) => {
            setEventsList(res.events)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const getEvents = async (searchText, from, to) => {
        setLoading(true)
        return new Promise(async (resolve, reject) => {
            let channels = []
            selectedChannels.map((c) => {
                channels.push(c.id)
            })
            let targets = []
            selectedTargets.map((t) => {
                targets.push(t.id)
            })
            let fday = from.getFullYear().toString() + "-" + (from.getMonth() + 1).toString() + "-" + from.getDate().toString()
            let lday = to.getFullYear().toString() + "-" + (to.getMonth() + 1).toString() + "-" + to.getDate().toString()
            let options = {
                "search_text": searchText,
                "from_date": fday,
                "to_date": lday,
                "search_target_ids": targets,
                "search_channel_ids": channels
            }

            rtS.getEvents(options).then((data) => {
                setCalendarEvents(data)
                setLoading(false)
                resolve(data)

            }).catch(() => {
                reject('err')
            })
        })
    }

    const setCalendarEvents = (data) => {
        let events = []
        setAllEventsCount(data.count)
        data.events.map((event) => {
            let e = {
                start: new Date(event.start_date),
                end: new Date(event.end_date),
                id: event.id,
                wishlist: event.wishlist,
                has_event: true,
                color: event.type.color
            }
            events.push(e)
        })
        setAllEvents(events)
    }

    const setFav = (event, id) => {
        event.stopPropagation()
        setLoading(true)
        rtS.addFav(id).then(() => {
            props.reloadWishlist()
            let s = selectedEvent
            s.wishlist = !s.wishlist
            setSelectedEvent(s)
            getEventsByDay(selectedDate)
            setLoading(false)
        })
    }

    const deleteFav = (event, id) => {
        event.stopPropagation()
        setLoading(true)
        rtS.deleteFav(id).then(() => {
            props.reloadWishlist()
            let s = selectedEvent
            s.wishlist = !s.wishlist
            setSelectedEvent(s)
            getEventsByDay(selectedDate)
            setLoading(false)
        })
    }

    const setFavShared = (event, id) => {
        event.stopPropagation()
        setLoading(true)
        rtS.addFav(id).then(() => {
            let sharedEv = props.sharedEvents.events
            sharedEv.map((e) => {
                if (e.id == id)
                    e.wishlist = !e.wishlist
            })
            let tmp = props.sharedEvents
            tmp.events = sharedEv
            props.setSharedEvents(tmp)
            setLoading(false)
        })
    }
    const setFavList = (event, id) => {
        event.stopPropagation()
        setLoading(true)
        rtS.addFav(id).then(() => {
            let events = eventsList
            events.map((e) => {
                if (e.id == id)
                    e.wishlist = !e.wishlist
            })
            setEventsList(events)
            setLoading(false)
        })
    }
    const deleteFavList = (event, id) => {
        event.stopPropagation()
        setLoading(true)
        rtS.deleteFav(id).then(() => {
            let events = eventsList
            events.map((e) => {
                if (e.id == id)
                    e.wishlist = !e.wishlist
            })
            setEventsList(events)
            setLoading(false)
        })
    }

    const deleteFavShared = (event, id) => {
        event.stopPropagation()
        setLoading(true)
        rtS.deleteFav(id).then(() => {
            let sharedEv = props.sharedEvents.events
            sharedEv.map((e) => {
                if (e.id == id)
                    e.wishlist = !e.wishlist
            })
            let tmp = props.sharedEvents
            tmp.events = sharedEv
            props.setSharedEvents(tmp)
            setLoading(false)
        })
    }

    const formatData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    const getEventByID = (event) => {

        setLoading(true)
        rtS.getEventByID(event.id).then((data) => {
            setSelectedEvent(data)
            if (props.isSharedView || props.isWishlistView) {
                setEventDetailSubMenu(true)
                props.setShowFilter(false)
            }
            else {
                setEventDetail(true)
                props.setShowFilter(false)
            }

            //staging if
            //if (window.location.hostname !== 'retailtrainingcalendar.gucci.cn' && window.location.hostname !== 'retailtrainingcalendar.gucci.com' && window.location.hostname !== 'localhost')
            if (window.location.hostname === 'hrintegration.globalbit.gucci')
                window.history.pushState({ page: "display_page" }, "display_page", '/digital_calendar/' + event.id);
            else
                window.history.pushState({ page: "display_page" }, "display_page", '/' + event.id);

            setTimeout(() => {
                if (event.type.code === 'TRAINING')
                    props.setLink(event.id)
            }, 500);
            setLoading(false)
        })

    }
    const getEventByIDValue = (id) => {
        if (id !== 'digital_calendar') {
            setLoading(true)
            rtS.getEventByID(id).then((data) => {
                setSelectedEvent(data)
                if (props.isSharedView || props.isWishlistView) {
                    setEventDetailSubMenu(true)
                    props.setShowFilter(false)
                }
                else {
                    setEventDetail(true)
                    props.setShowFilter(false)
                }
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
                //staging if
                // if (window.location.hostname !== 'retailtrainingcalendar.gucci.cn' && window.location.hostname !== 'retailtrainingcalendar.gucci.com' && window.location.hostname !== 'localhost')
                if (window.location.hostname === 'hrintegration.globalbit.gucci')
                    window.history.pushState({ page: "display_page" }, "display_page", '/digital_calendar/');
                else
                    window.history.pushState({ page: "display_page" }, "display_page", '/');
                // UseMessage(400, error.response.data.Message)
            })
        }
    }

    const getSuggest = (array) => {
        let s = ''
        array.map((a, index) => {
            if (index > 0)
                s += ', '
            s += a.full_name
        })

        return s
    }

    //to check
    const getMonth = async (e) => {
        let values = monthValues
        let month_name = new Date(e.start_date).toLocaleString('default', { month: 'long' })
        if (!monthValues.includes(month_name)) {
            values.push(month_name)
            setMonthValues(values)
            return month_name + ''
        }
        else {
            return ''
        }
    }



    //calendar util var
    const localizer = momentLocalizer(moment)

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    const selectDay = (event) => {
        setLoading(true)
        let date = event.start
        let newDateString, newDifferenceString
        setSelectedDate(getData(date))
        getEventsByDay(getData(date))
        let differenceDays = datediff(new Date().setHours(0, 0, 0, 0), date.setHours(0, 0, 0, 0));
        if (differenceDays == null) {
            newDateString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
        } else if (differenceDays === 0) {
            newDateString = "TODAY";
            newDifferenceString = "TODAY";
        } else if (differenceDays === 1) {
            newDateString = "TOMORROW";
            newDifferenceString = "TOMORROW";
        } else if (differenceDays === -1) {
            newDateString = "YESTERDAY";
            newDifferenceString = "YESTERDAY";
        } else if (differenceDays > 0) {
            newDateString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = differenceDays + " DAYS FROM TODAY";
        } else {
            newDateString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = Math.abs(differenceDays) + " DAYS AGO";
        }

        setDateString(newDifferenceString)

    }

    const selectEmptyDay = (date) => {
        setSelectedDate(getData(date))
        getEventsByDay(getData(date))
        let newDateString, newDifferenceString
        let differenceDays = datediff(new Date().setHours(0, 0, 0, 0), date.setHours(0, 0, 0, 0));
        if (differenceDays == null) {
            newDateString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
        } else if (differenceDays === 0) {
            newDateString = "TODAY";
            newDifferenceString = "TODAY";
        } else if (differenceDays === 1) {
            newDateString = "TOMORROW";
            newDifferenceString = "TOMORROW";
        } else if (differenceDays === -1) {
            newDateString = "YESTERDAY";
            newDifferenceString = "YESTERDAY";
        } else if (differenceDays > 0) {
            newDateString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = differenceDays + " DAYS FROM TODAY";
        } else {
            newDateString = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = Math.abs(differenceDays) + " DAYS AGO";
        }

        setDateString(newDifferenceString)
    }

    const back = () => {
        props.setLink(null)
        setLoading(true)

        if (eventDetailSubMenu && (props.isSharedView || props.isWishlistView)) {
            setEventDetailSubMenu(false)
            props.setShowFilter(true)
        } else {
            setLoading(true)
            setEventDetail(false)
            props.setShowFilter(true)
            props.setWishlistView(false)
            props.setIsSharedView(false)
        }
        //staging if
        // if (window.location.hostname !== 'retailtrainingcalendar.gucci.cn' && window.location.hostname !== 'retailtrainingcalendar.gucci.com' && window.location.hostname !== 'localhost')
        if (window.location.hostname === 'hrintegration.globalbit.gucci')
            window.history.pushState({ page: "display_page" }, "display_page", '/digital_calendar/');
        else
            window.history.pushState({ page: "display_page" }, "display_page", '/');
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }


    const datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }

    const changeMonth = (label, action) => {

        setLoading(true)

        let currentDate = new Date('01 ' + label)
        let fromDate = new Date(currentDate)
        let toDate = new Date(currentDate)

        if (action === 'NEXT') {
            //primo giorno del mese prossimo
            fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)

            //+1,0 prendo l'ultimo giorno del mese attuale
            //+2,0 prendo l'ultimo giorno del mese prossimo
            toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0)
        }
        else {
            //primo giorno del mese scorso
            fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)

            //ultimo giorno del mese scorso
            toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
        }
        //seleziono il primo del mese per visualizzare correttamente i mesi che scorrono
        setSelectedDate(fromDate)

        getEvents(searchText, fromDate, toDate)

    }

    const eventPropGetter = () => {
        const style = {
            backgroundColor: "#FF0000",
            paddingLeft: "10px",
            color: 'white',
        };
        return {
            style: style
        };
    }

    const setModalStatus = (value) => {
        setLoading(true)
        rtS.getTeamUsers(selectedEvent.id).then((res) => {
            let result = []
            res.map((r) => {
                result.push({
                    label: r.is_suggested && r.suggested_by_me ?
                        <div Style={window.innerWidth > 700 ? 'min-width: 480px;' : 'min-width: 210px;'}> <div Style={'float: left;'}>{r.full_name}</div><div Style={"float: right;font-size: 12px;font-family: 'GucciSans-Book';margin-top: 3px;"}>already shared </div></div>
                        : (!r.is_suggested
                            ?
                            <div>{r.full_name}</div>
                            : <div Style={window.innerWidth > 700 ? 'min-width: 480px;' : 'min-width: 210px;'}> <div Style={'float: left;'}>{r.full_name}</div><div Style={"float: right;font-size: 12px;font-family: 'GucciSans-Book';margin-top: 3px;"}>already shared </div></div>
                        ),
                    value: r.workday_id,
                    disabled: r.is_suggested && r.suggested_by_me ? true : false
                })
            })
            setUsersList(result)
            setOpenModal(value)
            //do not remove - loader bloccato su ios
            setTimeout(() => {
                setLoading(false)
            }, 200);
        }).catch(() => {
            //do not remove - loader bloccato su ios
            setTimeout(() => {
                setLoading(false)
            }, 200);
        })
    }

    const cancel = () => {
        setOpenModal(false)
        setUsersListSelected([])
    }

    const suggest = () => {
        setConfirmDisabled(true)
        setLoading(true)
        let values = []
        usersListSelected.map((u) => {
            values.push(u.value)
        })
        rtS.suggestEvent(selectedEvent.id, { employee_ids: values }).then(() => {

            setUsersListSelected([])
            setOpenModal(false)
        }).catch(() => {
            setUsersListSelected([])
            setOpenModal(false)
        }).finally(() => {
            setLoading(false)
            setConfirmDisabled(false)
        })

    }

    const loadMore = () => {
        props.getSharedEventMore()
    }

    const getListItems = () => {
        return new Promise(async (resolve, reject) => {
            setLoading(true)
            let month = new Date().getMonth() + 1
            let year = new Date().getFullYear()
            if (month == 12) {
                month = 0
                year += 1
            }
            let to = new Date(year, month, 0);
            let from = new Date()
            let fday = from.getFullYear().toString() + "-" + (from.getMonth() + 1).toString() + "-" + from.getDate().toString()
            let lday = to.getFullYear().toString() + "-" + (to.getMonth() + 1).toString() + "-" + to.getDate().toString()
            let channels = []
            selectedChannels.map((c) => {
                channels.push(c.id)
            })
            let targets = []
            selectedTargets.map((t) => {
                targets.push(t.id)
            })
            let options = {
                "search_text": searchText,
                "from_date": fday,
                "to_date": lday,
                "search_target_ids": targets,
                "search_channel_ids": channels
            }
            setLastTo(to)
            setLastFrom(from)
            rtS.getEvents(options).then((res) => {
                setListHasMore(res.has_more)
                setListHasPrev(res.has_prev)
                setNextEventDate(res.next_month)
                setPrevEventDate(res.prev_month)
                setEventsList(res.events)
                setSelectedItem('list')
                setLoading(false)
                resolve(res)
            }).catch(() => {
                reject('err')
            })
        })
    }
    const getListItemsFiltered = () => {
        return new Promise(async (resolve, reject) => {
            setLoading(true)
            let month = new Date().getMonth() + 1
            let year = new Date().getFullYear()
            if (month == 12) {
                month = 0
                year += 1
            }
            let to = new Date(year, month, 0);
            let fday = lastFrom.getFullYear().toString() + "-" + (lastFrom.getMonth() + 1).toString() + "-" + lastFrom.getDate().toString()
            let lday = to.getFullYear().toString() + "-" + (to.getMonth() + 1).toString() + "-" + to.getDate().toString()
            let channels = []
            selectedChannels.map((c) => {
                channels.push(c.id)
            })
            let targets = []
            selectedTargets.map((t) => {
                targets.push(t.id)
            })
            let options = {
                "search_text": searchText,
                "from_date": fday,
                "to_date": lday,
                "search_target_ids": targets,
                "search_channel_ids": channels
            }
            setLastTo(to)
            setLastFrom(lastFrom)
            rtS.getEvents(options).then((res) => {
                setListHasMore(res.has_more)
                setListHasPrev(res.has_prev)
                setNextEventDate(res.next_month)
                setPrevEventDate(res.prev_month)
                let next_res = []
                let month = -1
                res.events.map((e, i) => {
                    if (i == 0 || new Date(e.start_date).getMonth() != month) {
                        e.firstOfMonth = true
                        month = new Date(e.start_date).getMonth()
                    }
                    else {
                        e.firstOfMonth = false
                    }
                    next_res.push(e)
                })
                setEventsList(next_res)
                setSelectedItem('list')
                setLoading(false)
                resolve(res)
            }).catch(() => {
                reject('err')
            })
        })
    }

    const loadMoreList = (value) => {
        setLoading(true)
        let options = {}
        let el = document.getElementsByClassName('center_component_container')[0]
        let tmpHeight = el.scrollHeight
        if (value === 'next') {

            //FROM
            let month = lastTo.getMonth() + 1
            let year = lastTo.getFullYear()
            if (month == 12) {
                month = 0
                year += 1
            }
            let from = new Date(year, month, 1);

            //TO
            let month_to = parseInt(nextEventDate.split('-')[1])
            let year_to = parseInt(nextEventDate.split('-')[0])

            let to = new Date(year_to, month_to, 0);

            let fday = from.getFullYear().toString() + "-" + (from.getMonth() + 1).toString() + "-" + from.getDate().toString()
            let lday = to.getFullYear().toString() + "-" + (to.getMonth() + 1).toString() + "-" + to.getDate().toString()
            options = {
                "search_text": searchText,
                "from_date": fday,
                "to_date": lday
            }
            setLastTo(to)

        } else {
            //FROM
            let month = parseInt(prevEventDate.split('-')[1])
            let year = parseInt(prevEventDate.split('-')[0])
            let from = new Date(year, month - 1, 1);
            let month_to = ''
            let year_to = ''
            let day = 0
            if (firstTimePrev) {
                //TO
                month_to = new Date().getMonth()
                year_to = new Date().getFullYear()
                day = new Date().getDate() - 1
                if (day == 0)
                    month_to -= 1
                /*  if (month_to == 0)
                      year_to -= 1*/
                // else { day = 0 }
            } else {
                //TO
                month_to = lastFrom.getMonth() + 1
                year_to = lastFrom.getFullYear()
            }
            let to = new Date(year_to, month_to, day);
            if (lastTo) {
                to = lastTo
            }
            let fday = from.getFullYear().toString() + "-" + (from.getMonth() + 1).toString() + "-" + from.getDate().toString()
            let lday = to.getFullYear().toString() + "-" + (to.getMonth() + 1).toString() + "-" + to.getDate().toString()
            let channels = []
            selectedChannels.map((c) => {
                channels.push(c.id)
            })
            let targets = []
            selectedTargets.map((t) => {
                targets.push(t.id)
            })
            options = {
                "search_text": searchText,
                "from_date": fday,
                "to_date": lday,
                "search_target_ids": targets,
                "search_channel_ids": channels
            }
            setFirstTimePrev(false)
            setLastFrom(from)
        }

        rtS.getEvents(options).then((res) => {

            let next_res = []
            if (value === 'next') {
                setListHasMore(res.has_more)
                setNextEventDate(res.next_month)
                next_res = eventsList
                let month = ''
                res.events.map((e, i) => {
                    if (month === '') {
                        month = new Date(e.start_date).getMonth()
                        e.firstOfMonth = true
                    }
                    else if (month != new Date(e.start_date).getMonth()) {
                        month = new Date(e.start_date).getMonth()
                        e.firstOfMonth = true
                    }
                    else {
                        e.firstOfMonth = false
                    }
                    next_res.push(e)
                })
                setEventsList(next_res)
            }
            else {
                setListHasPrev(res.has_prev)
                setPrevEventDate(res.prev_month)
                let month = ''
                res.events.map((e, i) => {
                    if (month === '') {
                        month = new Date(e.start_date).getMonth()
                        e.firstOfMonth = true
                    }
                    else if (month != new Date(e.start_date).getMonth()) {
                        month = new Date(e.start_date).getMonth()
                        e.firstOfMonth = true
                    }
                    else {
                        e.firstOfMonth = false
                    }
                    next_res.push(e)
                })
                /*   eventsList.map((ev) => {
                       next_res.push(ev)
                   })*/
                setEventsList(next_res)
                el.scrollTop = 0
            }
            setLoading(false)
            setTimeout(() => {
                if (value === 'next')
                    el.scrollTo(0, tmpHeight - 200)
                else {
                    el.scrollTo(0, 0)
                }
            }, 200);
        })
    }

    const selectFilter = (e, type) => {
        if (type === 'channels')
            setSelectedChannels(e)
        else if (type === 'targets')
            setSelectedTargets(e)
    }
    const getMixButton = (date, label, ev) => {
        let color1 = ''
        let color2 = ''
        if (ev.length == 2) {
            color1 = ev[0].color
            color2 = ev[1].color

            return <button type="button" class={date.setHours(0, 0, 0, 0) === new Date(selectedDate).setHours(0, 0, 0, 0) ? "rbc-button-link  selected_day_calendar " : "rbc-button-link event_day"} Style={date.setHours(0, 0, 0, 0) !== new Date(selectedDate).setHours(0, 0, 0, 0) ? 'border-radius: 50%; background: linear-gradient(to left, ' + color1 + ' 50%, ' + color2 + ' 50%);' : ''} role="cell" onClick={() => selectEmptyDay(date)}>{label}</button>
        } else if (ev.length > 0) {
            color1 = ev[0].color
            let found = false
            ev.map((e) => {
                if (e.color !== color1 && !found) {
                    color2 = e.color
                    found = true
                }
            })
            if (color2 !== '' && color1 !== color2)
                return <button type="button" class={date.setHours(0, 0, 0, 0) === new Date(selectedDate).setHours(0, 0, 0, 0) ? "rbc-button-link  selected_day_calendar " : "rbc-button-link event_day"} Style={date.setHours(0, 0, 0, 0) !== new Date(selectedDate).setHours(0, 0, 0, 0) ? 'border-radius: 50%; background: linear-gradient(to left, ' + color1 + ' 50%, ' + color2 + ' 50%);' : ''} role="cell" onClick={() => selectEmptyDay(date)}>{label}</button>
            else
                return <button type="button" class={date.setHours(0, 0, 0, 0) === new Date(selectedDate).setHours(0, 0, 0, 0) ? "rbc-button-link selected_day_calendar event_day" : "rbc-button-link event_day "} role="cell" Style={date.setHours(0, 0, 0, 0) !== new Date(selectedDate).setHours(0, 0, 0, 0) ? "background-color: " + color1 : ''} onClick={() => selectEmptyDay(date)}>{label}</button>
        }
    }

    const copy = () => {
        navigator.clipboard.writeText(window.location.origin + '/' + selectedEvent.id + '/')
        UseMessage(200, 'Event link copied!')
    }
    const copyQr = () => {
        let path = null
        if (selectedEvent.has_survey)
            path = '/survey/'
        else if (selectedEvent.tracked)
            path = '/track/'

        navigator.clipboard.writeText(window.location.origin + path + selectedEvent.id + '/')
        UseMessage(200, 'Event link copied!')
    }

    const clear = () => {
        setLoadingFilters(true)


        setSelectedChannels([])
        setSelectedTargets([])
        setApply(false)
        props.setEnabledFilters(false)

        //CLEARED GET EVENT
        let tmpselected = new Date(selectedDate)
        let from = new Date(tmpselected.getFullYear(), tmpselected.getMonth(), 1)
        let to = new Date(tmpselected.getFullYear(), tmpselected.getMonth() + 1, 0)
        let fday = from.getFullYear().toString() + "-" + (from.getMonth() + 1).toString() + "-" + from.getDate().toString()
        let lday = to.getFullYear().toString() + "-" + (to.getMonth() + 1).toString() + "-" + to.getDate().toString()
        let options = {
            "search_text": searchText,
            "from_date": fday,
            "to_date": lday,
            "search_target_ids": [],
            "search_channel_ids": []
        }

        rtS.getEvents(options).then((data) => {

            if (selectedDate)
                resetEventsByDay(selectedDate)
            setCalendarEvents(data)
            setLoadingFilters(false)
            props.setFiltersTab(false)

        }).catch(() => {
            setLoadingFilters(false)
            props.setFiltersTab(false)
        })

    }
    const filter = async () => {
        if (selectedChannels && selectedChannels.length > 0 || selectedTargets && selectedTargets.length > 0)
            props.setEnabledFilters(true)
        else {
            props.setEnabledFilters(false)
        }
        let tmpselected = new Date(selectedDate)
        if (selectedItem === 'calendar')
            getEvents('', new Date(tmpselected.getFullYear(), tmpselected.getMonth(), 1), new Date(tmpselected.getFullYear(), tmpselected.getMonth() + 1, 0)).then(() => {
                setApply(true)
                props.setFiltersTab(false)
            })
        else
            getListItemsFiltered().then(() => {
                setApply(true)
                props.setFiltersTab(false)
            })
        if (selectedDate) {
            let formattedDate = selectedDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
            formattedDate = formattedDate.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
            getEventsByDay(formattedDate)
        }

    }
    const downloadMaterials = (file) => {
        rtS.getMaterialFile(selectedEvent.id, file);
    }
    const getMaterialSvg = (filename) => {
        let extension = filename.split('.').pop();
        switch (extension) {
            case 'xlsx':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-xlsx mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                </svg>
            case 'xls':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-xls mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM6.472 15.29a1.2 1.2 0 0 1-.111-.449h.765a.58.58 0 0 0 .254.384q.106.073.25.114.143.041.319.041.246 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .085-.29.39.39 0 0 0-.153-.326q-.152-.12-.462-.193l-.619-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.351-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.19-.272.527-.422.338-.15.777-.149.457 0 .78.152.324.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.247-.181.9.9 0 0 0-.369-.068q-.325 0-.513.152a.47.47 0 0 0-.184.384q0 .18.143.3a1 1 0 0 0 .405.175l.62.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94zm2.727 3.325H4.557v-3.325h-.79v4h2.487z" />
                </svg>
            case 'pdf':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-pdf mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                </svg>
            case 'ppt':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-ppt mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m2.817-1.333h-1.6v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474.162-.302.161-.677 0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H4.15V12.48h.66q.327 0 .512.181.185.183.185.522m2.767-.67v3.336H7.48v-3.337H6.346v-.662h3.065v.662z" />
                </svg>
            case 'pptx':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-pptx mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.5 11.85h1.6q.434 0 .732.179.302.175.46.477t.158.677-.16.677q-.159.299-.464.474a1.45 1.45 0 0 1-.732.173H2.29v1.342H1.5zm2.06 1.714a.8.8 0 0 0 .085-.381q0-.34-.185-.521-.185-.182-.513-.182h-.659v1.406h.66a.8.8 0 0 0 .374-.082.57.57 0 0 0 .238-.24m1.302-1.714h1.6q.434 0 .732.179.302.175.46.477t.158.677-.16.677q-.158.299-.464.474a1.45 1.45 0 0 1-.732.173h-.803v1.342h-.79zm2.06 1.714a.8.8 0 0 0 .085-.381q0-.34-.185-.521-.184-.182-.513-.182H5.65v1.406h.66a.8.8 0 0 0 .374-.082.57.57 0 0 0 .238-.24m2.852 2.285v-3.337h1.137v-.662H7.846v.662H8.98v3.337zm3.796-3.999h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.439h.035z" />
                </svg>
            case 'doc':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-doc mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-7.839 9.166v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.469.126.9.9 0 0 1-.471-.126.87.87 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .471-.129q.264 0 .469.13a.86.86 0 0 1 .322.386q.117.255.117.641m.803.519v-.513q0-.565-.205-.972a1.46 1.46 0 0 0-.589-.63q-.381-.22-.917-.22-.533 0-.92.22a1.44 1.44 0 0 0-.589.627q-.204.406-.205.975v.513q0 .563.205.973.205.406.59.627.386.216.92.216.535 0 .916-.216.383-.22.59-.627.204-.41.204-.973M0 11.926v4h1.459q.603 0 .999-.238a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.59-.68q-.395-.234-1.004-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082H.79V12.57Zm7.422.483a1.7 1.7 0 0 0-.103.633v.495q0 .369.103.627a.83.83 0 0 0 .298.393.85.85 0 0 0 .478.131.9.9 0 0 0 .401-.088.7.7 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.27 1.27 0 0 1-.226.674q-.205.29-.55.454a1.8 1.8 0 0 1-.786.164q-.54 0-.914-.216a1.4 1.4 0 0 1-.571-.627q-.194-.408-.194-.976v-.498q0-.568.197-.978.195-.411.571-.633.378-.223.911-.223.328 0 .607.097.28.093.489.272a1.33 1.33 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.8.8 0 0 0-.398-.097.8.8 0 0 0-.475.138.87.87 0 0 0-.301.398" />
                </svg>
            case 'docx':
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-docx mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-6.839 9.688v-.522a1.5 1.5 0 0 0-.117-.641.86.86 0 0 0-.322-.387.86.86 0 0 0-.469-.129.87.87 0 0 0-.471.13.87.87 0 0 0-.32.386 1.5 1.5 0 0 0-.117.641v.522q0 .384.117.641a.87.87 0 0 0 .32.387.9.9 0 0 0 .471.126.9.9 0 0 0 .469-.126.86.86 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642m.803-.516v.513q0 .563-.205.973a1.47 1.47 0 0 1-.589.627q-.381.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.46 1.46 0 0 1-.589-.627 2.15 2.15 0 0 1-.205-.973v-.513q0-.569.205-.975.205-.411.59-.627.386-.22.92-.22.535 0 .916.22.383.219.59.63.204.406.204.972M1 15.925v-3.999h1.459q.609 0 1.005.235.396.233.589.68.196.445.196 1.074 0 .634-.196 1.084-.197.451-.595.689-.396.237-.999.237zm1.354-3.354H1.79v2.707h.563q.277 0 .483-.082a.8.8 0 0 0 .334-.252q.132-.17.196-.422a2.3 2.3 0 0 0 .068-.592q0-.45-.118-.753a.9.9 0 0 0-.354-.454q-.237-.152-.61-.152Zm6.756 1.116q0-.373.103-.633a.87.87 0 0 1 .301-.398.8.8 0 0 1 .475-.138q.225 0 .398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.49-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.571.633-.197.41-.197.978v.498q0 .568.194.976.195.406.571.627.375.216.914.216.44 0 .785-.164t.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.7.7 0 0 1-.273.248.9.9 0 0 1-.401.088.85.85 0 0 1-.478-.131.83.83 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035z" />
                </svg>
            default:
                return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark mr-2" viewBox="0 0 16 16">
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                </svg>
        }


    }
    //useful_links
    let links = ''
    if (selectedEvent && selectedEvent.useful_links && selectedEvent.useful_links.length > 0)
        links = selectedEvent.useful_links.map((u) => <div><a href={u.value} target='_blank'>{u.label ? u.label : u.value}</a></div>)
    //materials
    let materials = ''
    if (selectedEvent && selectedEvent.materials && selectedEvent.materials.length > 0)
        materials = selectedEvent.materials.map((u) => <div className='flex'>{getMaterialSvg(u.filename)}<div onClick={() => downloadMaterials(u)} className='link' >{u.filename ? u.filename.split('.').slice(0, -1).join('.') : ''}</div></div>)
    const multiselectStyle = {
        chips: {
            background: '#000',
            'font-size': '12px',
            'border-radius': '15px',
            'line-height': '14px',
            'margin-bottom': '1px'
        },
        multiselectContainer: {
            color: '#000',
        },
        searchBox: {
            border: 'none',
            'border-bottom': '1px solid #000',
            'border-radius': '0px',
            cursor: 'pointer',
            'margin-top': '10px'
        }
    }
    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };
    const opentext = (ev, e, eventArray, setEventsArray, type) => {
        ev.stopPropagation();
        let events = eventArray
        if (type === 'detail') {
            events['expanded'] = true
            //set the selectedEvent
            setEventsArray({ ...events })
        } else {

            events.map((myevent) => {
                if (myevent.id == e.id)
                    if (!myevent['expanded']) {
                        myevent['expanded'] = true
                    }
            })
            if (type === 'wishlist') {
                let value = props.wishlist
                value['events'] = events
                setEventsArray({ ...value })
            } else if (type === 'shared') {
                let value = props.sharedEvents
                value['events'] = events
                setEventsArray({ ...value })
            } else
                setEventsArray([...events])
        }
    }
    const closetext = (ev, e, eventArray, setEventsArray, type) => {
        ev.stopPropagation();
        let events = eventArray
        if (type === 'detail') {
            events['expanded'] = false
            //set the selectedEvent
            setEventsArray({ ...events })
        } else {
            events.map((myevent) => {
                if (myevent.id == e.id)
                    myevent['expanded'] = false
            })
            if (type === 'wishlist') {
                let value = props.wishlist
                value['events'] = events
                setEventsArray({ ...value })
            } else if (type === 'shared') {
                let value = props.sharedEvents
                value['events'] = events
                setEventsArray({ ...value })
            } else
                setEventsArray([...events])
        }
    }
    const addToCalendar = () => {
        rtS.addToCalendar(selectedEvent.id).then((res) => {
            UseMessage(200, res.message)
        }).catch((err) => {
            UseMessage(400, err.message)
        })
    }
    let events_list = []
    if (eventsList && eventsList.length > 0)
        events_list = eventsList.map((e) => <React.Fragment>
            <div className='month_name_shared' >
                {e.firstOfMonth ? new Date(e.start_date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(e.start_date).getFullYear() : ''}   </div>

            <div className='calendar_list_item pointer' Style={'position:relative;border-left: 3px ' + e.type.color + ' solid;'} >
                <div className='flex' Style={"align-items: center;"} onClick={() => getEventByID(e)}>
                    <div Style={'position:relative'}>

                        <div className='flex'  > {e.type.code === 'LUCE' ? '' : <div className={!e['expanded'] ? 'course_type ellipsis course_type_dim_sm' : 'course_type course_type_dim_sm'}  >{e.location}</div>}
                        </div>
                        {e.title && e.type.code === 'LUCE' ? <div className='flex'> <div className='label_text'>Title: </div><div className='course_type_title'>{e.title}</div> </div> : <div className='course_type_title'>{e.title}</div>}
                        <div className='topic_type'><div className='flex'> <div className='label_text'>Category: </div>{e.topic.name}</div></div>
                        {e.type.code === 'LUCE' ? '' : (e.category && e.category.name ? <div className='category_type'>{e.category.name}</div> : '')}
                        {e.type.code === 'LUCE' ? <div className='flex'> <div className='label_text'>Target: </div><div className={!e['expanded'] ? 'course_type ellipsis_card course_type_dim_s' : 'course_type_s course_type'} >{e.location}</div> </div> : ''}
                        <div className='category_type'> {e.suggested_by && e.suggested_by.length == 1 && e.suggested_by[0] ? 'Shared by ' + e.suggested_by[0].full_name : (e.suggested_by && e.suggested_by.length > 1 ? 'Suggested by ' + getSuggest(e.suggested_by) : '')}</div>
                        {e.type.code === 'LUCE' ? <div Style={"font-family: 'GucciSans-Book'"}><div className='flex'> <div className='label_text'></div>{e.status}</div></div> : ''}
                    </div>
                    {e.type.code === 'LUCE' ? '' :
                        e.wishlist ?
                            <div className='event_list_icon pointer' onClick={(event) => deleteFav(event, e.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart-fill favorite_icon" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                                </svg>
                            </div>
                            :
                            <div className='event_list_icon pointer' onClick={(event) => setFav(event, e.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart favorite_icon" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg>
                            </div>
                    }
                </div>
                {e.location && e.location.length > 45 ? (!e['expanded'] ?
                    <div Style={'position:absolute; right:44px;top:2px'} onClick={(ev) => opentext(ev, e, eventsList, setEventsList, 'event_list')} >

                        {e['expanded']}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    </div> :
                    <div Style={'position:absolute; right:43px;top:1px'} onClick={(ev) => closetext(ev, e, eventsList, setEventsList, 'event_list')} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg></div>) : ''}
            </div>
        </React.Fragment >)
    else
        events_list = <div Style={"font-family: 'GucciSans-Book'"}>No events for the selected day</div>


    //list events

    let events_card_list = []
    if (eventsList && eventsList.length > 0) {
        events_card_list = eventsList.map((e) =>
            <React.Fragment>
                <div className='month_name_shared' >
                    {e.firstOfMonth ? new Date(e.start_date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(e.start_date).getFullYear() : ''}   </div>

                <div className='event_card_list pointer' onClick={() => getEventByID(e)}>
                    <div className='card_image_container' Style={"background-image: url('" + e.image_url + "');  background-position: 50%"}>
                        {e.type.code === 'LUCE' ? '' : <div className='card_image_wishlist'>
                            {e.wishlist ?
                                <div className='event_list_icon pointer' onClick={(event) => deleteFavList(event, e.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart-fill favorite_icon_card" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                                    </svg>
                                </div>
                                :
                                <div className='event_list_icon pointer' onClick={(event) => setFavList(event, e.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart favorite_icon_card" viewBox="0 0 16 16">
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                    </svg>
                                </div>
                            }
                        </div>}
                    </div>

                    {e.is_suggested ? <div class='suggested_by'> {e.suggested_by && e.suggested_by.length == 1 ? 'Suggested by ' + e.suggested_by[0].full_name : (e.suggested_by && e.suggested_by.length > 1 ? 'Suggested by ' + getSuggest(e.suggested_by) : '')} </div> : ''}

                    <div className='card_text_container' Style={'position:relative;'}>
                        <div>
                            <div className='flex'  >
                                {e.type.code === 'LUCE' ? '' : <div className={!e['expanded'] ? 'course_type ellipsis_card course_type_dim_s' : 'course_type course_type_dim_s'} > {e.location}</div>}
                            </div>
                            {e.title && e.type.code === 'LUCE' ? <div className='flex'> <div className='label_text'>Title:</div><div className='course_type_title'> {e.title}</div></div> : <div className='course_type_title'> {e.title}</div>}
                            <div className='topic_type' Style={"margin-top: 10px;"}> <div className='flex'> <div className='label_text'>Category: </div>{e.topic.name}</div></div>
                            {e.type.code === 'LUCE' ? '' : (e.category && e.category.name ? <div className='category_type' Style={"text-transform: uppercase; margin-top: 10px;"}>{e.category.name}</div> : '')}
                        </div>
                        {e.type.code === 'LUCE' ? <div className='flex'> <div className='label_text'>Target: </div><div className={!e['expanded'] ? 'course_type ellipsis_card course_type_dim_s' : 'course_type_s course_type'} >{e.location}</div> </div> : ''}
                        <hr />
                        <div>
                            <div>
                                {e.type.code === 'LUCE' ? '' : <div className='flex vertical-align'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person margin-icon-card" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg>
                                    <div Style={"font-family: 'GucciSans-Bold'"}>{e.trainer && e.trainer.email ? e.trainer.email : ''}</div>
                                </div>}
                            </div>
                            <div Style={"margin-top: 20px;"}>
                                <div className='flex'>
                                    <div className='flex vertical-align card_column'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar margin-icon-card" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                            </svg>
                                        </div>
                                        {e.type.code === 'LUCE' ? <div Style={"font-family: 'GucciSans-Book'"}>{formatData(e.date)}</div> : <div Style={"font-family: 'GucciSans-Book'"}>{formatData(e.start_date)}</div>}
                                    </div>
                                    <div className='flex vertical-align card_column'>
                                        {e.type.code === 'LUCE' ?
                                            <div Style={"font-family: 'GucciSans-Book'"}>{e.status}

                                            </div> : <>    <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clock margin-icon-card" viewBox="0 0 16 16">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>
                                            </div>
                                                <div Style={"font-family: 'GucciSans-Book'"}>
                                                    {e.duration && e.duration.hours > 0 ? e.duration.hours : (e.duration && e.duration.minutes ? e.duration.minutes : '')} {e.duration && e.duration.hours > 0 ? 'hours' : 'minutes'}
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {e.location && e.location.length > 45
                            ? (!e['expanded'] ?
                                <div Style={'position:absolute; right: 12px; top: 11px;'} onClick={(ev) => opentext(ev, e, eventsList, setEventsList, 'event_card')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                :
                                <div Style={'position: absolute;  right: 12px;  top: 11px;'} onClick={(ev) => closetext(ev, e, eventsList, setEventsList, 'event_card')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                    </svg></div>
                            ) : ''}
                    </div >

                </div >
            </React.Fragment>)
    } else
        events_card_list = <div Style={"font-family: 'GucciSans-Book'"}>No events for the selected day</div>

    //wishlist events

    let wishlist_cards_list = []
    if (props.wishlist && props.wishlist.events && props.wishlist.events.length > 0)
        wishlist_cards_list = props.wishlist.events.map((e) =>
            <div className='event_card_list pointer' onClick={() => getEventByID(e)}>
                <div className='card_image_container' Style={"background-image: url('" + e.image_url + "'); background-position: 50%;"}>
                    <div className='card_image_wishlist'>
                        {e.wishlist ?
                            <div className='event_list_icon pointer' onClick={(event) => deleteFav(event, e.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart-fill favorite_icon_card" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                                </svg>
                            </div>
                            :
                            <div className='event_list_icon pointer' onClick={(event) => setFav(event, e.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart favorite_icon_card" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg>
                            </div>
                        }
                    </div>
                </div>

                <div className='card_text_container' Style={'position:relative'}>
                    <div>
                        {e.type.code === 'LUCE' ? '' : <div className={!e['expanded'] ? 'course_type ellipsis_card course_type_dim_s' : 'course_type course_type_dim_s'}> {e.location}</div>}
                        {e.title ? <div className='course_type_title'> {e.title}</div> : ''}
                        <div className='topic_type' Style={"margin-top: 10px;"}>{e.topic.name}</div>
                        {e.type.code === 'LUCE' ? '' : (e.category && e.category.name ? <div className='category_type' Style={"text-transform: uppercase; margin-top: 10px;"}>{e.category.name}</div> : '')}
                        {e.type.code === 'LUCE' ? <div className='course_type'> {e.location}</div> : ''}
                    </div>
                    <hr />
                    <div>
                        <div>
                            {e.type.code === 'LUCE' ? '' : <div className='flex vertical-align'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person margin-icon-card" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                </svg>
                                <div Style={"font-family: 'GucciSans-Bold'"}>{e.trainer.email}</div>
                            </div>}
                        </div>
                        <div Style={"margin-top: 20px;"}>
                            <div className='flex'>
                                <div className='flex vertical-align card_column'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar margin-icon-card" viewBox="0 0 16 16">
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg>
                                    </div>
                                    {e.type.code === 'LUCE' ? <div Style={"font-family: 'GucciSans-Book'"}>{formatData(e.date)}</div> : <div Style={"font-family: 'GucciSans-Book'"}>{formatData(e.start_date)}</div>}
                                </div>
                                <div className='flex vertical-align card_column'>
                                    {e.type.code === 'LUCE' ? <div Style={"font-family: 'GucciSans-Book'"}>{selectedEvent.status} </div>
                                        :
                                        <> <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clock margin-icon-card" viewBox="0 0 16 16">
                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                            </svg>
                                        </div>
                                            <div Style={"font-family: 'GucciSans-Book'"}>
                                                {e.duration && e.duration.hours > 0 ? e.duration.hours : (e.duration && e.duration.minutes ? e.duration.minutes : '')} {e.duration && e.duration.hours > 0 ? 'hours' : 'minutes'}
                                            </div></>}
                                </div>
                            </div>
                        </div>
                        {e.location && e.location.length > 45
                            ? (!e['expanded'] ?
                                <div Style={'position:absolute; right:10px;top:10px;z-index:20'} onClick={(ev) => opentext(ev, e, props.wishlist.events, props.setWishlistEvents, 'wishlist')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                :
                                <div Style={'position:absolute; right:10px;top:10px;z-index:20'} onClick={(ev) => closetext(ev, e, props.wishlist.events, props.setWishlistEvents, 'wishlist')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                    </svg></div>
                            ) : ''}
                    </div>

                </div>
            </div>)

    //shared events

    let shared_list = 'There are no events shared in the next few days of the current month'
    if (props.sharedEvents && props.sharedEvents.events && props.sharedEvents.events.length > 0)
        shared_list = props.sharedEvents.events.map((e) =>
            <React.Fragment>
                <div className='month_name_shared' >
                    {e.firstOfMonth ? new Date(e.start_date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(e.start_date).getFullYear() : ''}   </div>
                <div className='event_card_list pointer' onClick={() => getEventByID(e)}>
                    <div className='card_image_container' Style={"background-image: url('" + e.image_url + "');  background-position: 50%;"}>
                        <div className='card_image_wishlist'>
                            {e.wishlist ?
                                <div className='event_list_icon pointer' onClick={(event) => deleteFavShared(event, e.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart-fill favorite_icon_card" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                                    </svg>
                                </div>
                                :
                                <div className='event_list_icon pointer' onClick={(event) => setFavShared(event, e.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-heart favorite_icon_card" viewBox="0 0 16 16">
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                    </svg>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='card_text_container' Style={'position:relative'}>
                        <div>
                            {e.type.code === 'LUCE' ? '' : <div className={!e['expanded'] ? 'course_type ellipsis_card course_type_dim_s' : 'course_type_s'} >{e.location}</div>}
                            {e.title ? <div className='course_type_title'>{e.title}</div> : ''}
                            <div className='topic_type' Style={"margin-top: 10px;"}>{e.topic.name}</div>
                            {e.type.code === 'LUCE' ? '' : (e.category && e.category.name ? <div className='category_type' Style={"text-transform: uppercase; margin-top: 10px;"}>{e.category.name}</div> : '')}
                            {e.type.code === 'LUCE' ? <div className='course_type'>{e.location}</div> : ''}
                            <div className='category_type'> {e.suggested_by && e.suggested_by.length == 1 && e.suggested_by[0] ? 'Shared by ' + e.suggested_by[0].full_name : (e.suggested_by && e.suggested_by.length > 1 ? 'Suggested by ' + getSuggest(e.suggested_by) : '')}</div>

                        </div>
                        <hr />
                        <div>
                            <div>
                                <div className='flex vertical-align'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person margin-icon-card" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg>
                                    <div Style={"font-family: 'GucciSans-Bold'"}>{e.trainer.email}</div>
                                </div>
                            </div>
                            <div Style={"margin-top: 20px;"}>
                                <div className='flex'>
                                    <div className='flex vertical-align card_column'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar margin-icon-card" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                            </svg>
                                        </div>
                                        <div Style={"font-family: 'GucciSans-Book'"}>{formatData(e.start_date)}</div>
                                    </div>
                                    <div className='flex vertical-align card_column'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clock margin-icon-card" viewBox="0 0 16 16">
                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                            </svg>
                                        </div>
                                        <div Style={"font-family: 'GucciSans-Book'"}>
                                            {e.duration.hours} hours
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {e.location && e.location.length > 45
                            ? (!e['expanded'] ?
                                <div Style={'position:absolute; right:10px;top:10px;z-index:20'} onClick={(ev) => opentext(ev, e, props.sharedEvents.events, props.setSharedEvents, 'shared')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                :
                                <div Style={'position:absolute; right:10px;top:10px;z-index:20'} onClick={(ev) => closetext(ev, e, props.sharedEvents.events, props.setSharedEvents, 'shared')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                    </svg></div>
                            ) : ''}
                    </div>
                </div></React.Fragment>)
    return (
        <div className='center_component_container' Style={"width: 100%; text-align: -webkit-center;"}>
            <div className='center_component'>

                {loading || props.wishlistLoading ?
                    <div className="loader-cont">
                        <div className="loader1"></div>
                    </div>
                    :

                    (props.isSharedView && !eventDetailSubMenu ? <div>
                        <div className='event_cards_list' Style={'padding-bottom: 100px;'}>
                            <div className='event_wishlist_cards_container'>
                                <div id='back_wishlist_container' onClick={() => props.setIsSharedView(false)}>
                                    <div className='flex vertical-align pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                        <div className="back " >Back</div>
                                    </div>
                                </div>
                                {shared_list}

                                {props.sharedEvents.has_more ? <div className='pointer' onClick={() => loadMore()}><div className='loadMore_button'>Load Next</div>
                                </div> : ''}
                            </div>
                        </div>
                    </div> :
                        (props.isWishlistView && !eventDetailSubMenu ?
                            <div>
                                <div className='event_cards_list' Style={'padding-bottom: 100px;'}>
                                    <div className='event_wishlist_cards_container'>
                                        <div id='back_wishlist_container' >
                                            <div className='flex vertical-align pointer'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <div className="back " onClick={() => props.setWishlistView(false)}>Back</div>
                                            </div>
                                        </div>
                                        {wishlist_cards_list && wishlist_cards_list.length > 0 ? wishlist_cards_list : 'There are no events in your wishlist'}
                                    </div>
                                </div>
                            </div>
                            : eventDetail || eventDetailSubMenu ?

                                (selectedEvent.type.code === 'TRAINING' ?
                                    <React.Fragment>
                                        <div className='event_detail_container'>
                                            <div className='pointer' onClick={() => back()}>
                                                <div className='flex vertical-align pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                    </svg>
                                                    <div className="back">Back</div>
                                                </div>
                                            </div>
                                            <div className='event_detail_image' Style={"background-image: url('" + (selectedEvent.image_url && selectedEvent.image_url !== '' ? selectedEvent.image_url : 'https://rejse-glæde.dk/wp-content/themes/klikko3_b_18_07_2023/dest/images/no-image.png') + "'); background-position: 50%"}></div>

                                            <div Style={"margin-top: 20px; position:relative"}>
                                                <div>
                                                    {selectedEvent.type.code === 'LUCE' ? '' : <div className={!selectedEvent['expanded'] ? 'course_type ellipsis_detail course_type_dim_m' : 'course_type course_type_dim_m'}  >{selectedEvent.location}</div>}
                                                    {selectedEvent.title ? <div className='course_type_title'>{selectedEvent.title}</div> : ''}
                                                    <div className='topic_type' Style={"margin-top: 10px;"}>{selectedEvent.topic.name}</div>
                                                    <div className='flex'>

                                                        {selectedEvent.type.code === 'LUCE' ? '' : (selectedEvent.category && selectedEvent.category.name ?
                                                            <div className='category_type' Style={"text-transform: uppercase; margin-top: 10px; width:50%"}>
                                                                {selectedEvent.category.name}
                                                            </div>
                                                            : '')}
                                                    </div>
                                                    {selectedEvent.type.code === 'LUCE' ? <div className='course_type' Style={'max-width:520px'}>{selectedEvent.location}</div> : ''}
                                                    <div className='category_type' Style={'width:100%'} >

                                                        {user && user.can_suggest ?

                                                            <div className='flex' Style={"place-content: center;"}>

                                                                <div className='sugg_button_container' onClick={(() => setModalStatus(true))} Style={"margin-right: 10px;"}>

                                                                    <div className='add_sugg_button pointer flex' >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-megaphone-fill share_ico" viewBox="0 0 16 16">
                                                                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                                                                        </svg>
                                                                        <div> SUGGEST </div>
                                                                    </div>
                                                                </div>
                                                                <div className='sugg_button_container' onClick={(() => setFollowerModalStatus(true))}>
                                                                    <div className='add_sugg_button pointer flex' >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-heart-fill follower_ico" viewBox="0 0 16 16" onClick={() => props.getWishlist()}>
                                                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                                                                        </svg>
                                                                        <div> FOLLOWERS </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                        <div className='flex' Style={"place-content: center;     min-height: 30px"}>
                                                            {window.innerWidth > 1000 ?
                                                                ((selectedEvent.has_survey || selectedEvent.tracked) && user.can_suggest ?


                                                                    <div className='flex  add_sugg_button pointer sugg_button_container' onClick={() => copy()} Style={"margin-right: 10px;"}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy share_ico" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                                                        </svg>
                                                                        <div> COPY </div>

                                                                    </div>
                                                                    :
                                                                    <div className='flex' Style={"place-content: center;"}>
                                                                        <div className='calendar_button_container ' onClick={copy}>
                                                                            <div className='add_sugg_button pointer  flex' >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy cal_ico" viewBox="0 0 16 16">
                                                                                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                                                                </svg>
                                                                                <div Style={'margin-left:55px'}> COPY</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                :
                                                                <RWebShare
                                                                    data={{
                                                                        text: "Share",
                                                                        url: window.location.href,
                                                                        title: "Share",
                                                                    }}
                                                                >
                                                                    {(selectedEvent.has_survey || selectedEvent.tracked) && user.can_suggest ?
                                                                        <div className='flex  add_sugg_button pointer sugg_button_container' Style={"margin-right: 10px;"}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share share_ico" viewBox="0 0 16 16">
                                                                                <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                                                                            </svg>
                                                                            <div> SHARE </div>

                                                                        </div>
                                                                        :

                                                                        <div className='flex' Style={"place-content: center;"}>
                                                                            <div className='calendar_button_container '>
                                                                                <div className='add_sugg_button pointer  flex' >

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share cal_ico" viewBox="0 0 16 16">
                                                                                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                                                                                    </svg>
                                                                                    <div Style={'margin-left:55px'}> SHARE</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    }
                                                                </RWebShare>}
                                                            {(selectedEvent.has_survey || selectedEvent.tracked) && user.can_suggest ?
                                                                <div className='sugg_button_container ' onClick={(() => setQrTab(true))}>
                                                                    <div className='add_sugg_button pointer  flex'  >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code-scan share_ico" viewBox="0 0 16 16">
                                                                            <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z" />
                                                                            <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z" />
                                                                            <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z" />
                                                                            <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z" />
                                                                            <path d="M12 9h2V8h-2z" />
                                                                        </svg>
                                                                        <div> QR CODE </div>
                                                                    </div>
                                                                </div> : ''}
                                                        </div>
                                                        <div className='flex' Style={"place-content: center;"}>
                                                            <div className='calendar_button_container ' onClick={addToCalendar}>
                                                                <div className='add_sugg_button pointer  flex' >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3 cal_ico" viewBox="0 0 16 16">
                                                                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                                                                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                                                    </svg>
                                                                    <div> ADD TO CALENDAR</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr />
                                                <div>
                                                    {selectedEvent.type.code === 'LUCE' ? '' : <div className='flex vertical-align'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person margin-icon-card" viewBox="0 0 16 16">
                                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                                        </svg>
                                                        <div Style={"font-family: 'GucciSans-Bold'"}>{selectedEvent.trainer.email}</div>
                                                    </div>}
                                                </div>
                                                <div Style={"margin-top: 20px;"}>
                                                    <div className='flex'>
                                                        <div className='flex vertical-align card_column'>
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar margin-icon-card" viewBox="0 0 16 16">
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                </svg>
                                                            </div>
                                                            {selectedEvent.type.code === 'LUCE' ? <div Style={"font-family: 'GucciSans-Book'"}>{formatData(selectedEvent.date)}</div> : <div Style={"font-family: 'GucciSans-Book'"}>{formatData(selectedEvent.start_date)}</div>}
                                                        </div>
                                                        <div className='flex vertical-align card_column'>
                                                            {selectedEvent.type.code === 'LUCE' ?
                                                                <div Style={"font-family: 'GucciSans-Book'"}>{selectedEvent.status}
                                                                </div>
                                                                : <>
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clock margin-icon-card" viewBox="0 0 16 16">
                                                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div Style={"font-family: 'GucciSans-Bold'"}>

                                                                        {selectedEvent.duration.hours > 0 ? selectedEvent.duration.hours : selectedEvent.duration.minutes} {selectedEvent.duration.hours > 0 ? 'hours' : 'minutes'}
                                                                    </div>
                                                                </>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr Style={"margin-top: 20px;"} />
                                                <div>
                                                    {selectedEvent.description ? selectedEvent.description : ''}
                                                </div>
                                                <br />
                                                {selectedEvent.materials && selectedEvent.materials.length > 0 ? <div Style={"font-family: 'GucciSans-Bold'"}>Materials</div> : ''}
                                                <div className='materials_container'>
                                                    {selectedEvent.materials && selectedEvent.materials.length > 0 ? materials : ''}
                                                </div>
                                                {selectedEvent.location && selectedEvent.location.length > 45
                                                    ? (!selectedEvent['expanded'] ?
                                                        <div Style={'position:absolute; right:10px;top:1px;z-index:20'} onClick={(ev) => opentext(ev, selectedEvent, selectedEvent, setSelectedEvent, 'detail')} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                            </svg>
                                                        </div>
                                                        :
                                                        <div Style={'position:absolute; right:10px;top:1px;z-index:20'} onClick={(ev) => closetext(ev, selectedEvent, selectedEvent, setSelectedEvent, 'detail')} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                            </svg></div>
                                                    ) : ''}
                                            </div>

                                        </div>
                                        <div className='fav_button_container'>
                                            {!selectedEvent.wishlist ?
                                                <div className='add_fav_button pointer' onClick={(event) => setFav(event, selectedEvent.id)}>Add to wishlist</div>
                                                :
                                                <div className='add_fav_button pointer' onClick={(event) => deleteFav(event, selectedEvent.id)}>Remove from wishlist</div>
                                            }
                                        </div>
                                    </React.Fragment>
                                    :
                                    //evento luce
                                    <React.Fragment>
                                        <div className='event_detail_container'>
                                            <div className='pointer' onClick={() => back()}>
                                                <div className='flex vertical-align pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                    </svg>
                                                    <div className="back">Back</div>
                                                </div>
                                            </div>
                                            <div className='event_detail_image' Style={"background-image: url('" + (selectedEvent.image_url && selectedEvent.image_url !== '' ? selectedEvent.image_url : 'https://rejse-glæde.dk/wp-content/themes/klikko3_b_18_07_2023/dest/images/no-image.png') + "'); background-position: 50%"}></div>

                                            <div Style={"margin-top: 20px;position:relative"}>
                                                <div >
                                                    <div className='flex'>
                                                        <div className='label_text'>
                                                            Title: &nbsp;
                                                        </div>
                                                        <div className='  course_type_title '> {selectedEvent.title ? <div className='course_type_title'>{selectedEvent.title}</div> : ''}</div>
                                                    </div>
                                                    <div className='flex'>

                                                        {selectedEvent.category && selectedEvent.category.name ?
                                                            <div className='flex'>
                                                                <div className='label_text' Style={'margin-top:10px'}>     Category: &nbsp;</div>
                                                                <div className='category_type ' Style={"text-transform: uppercase; margin-top: 10px; width:50%"}>
                                                                    {selectedEvent.category.name}
                                                                </div>
                                                            </div>
                                                            : ''}
                                                    </div>

                                                    <div className='flex' Style={'margin-top:3px'}>
                                                        <div className='label_text'>
                                                            Target:&nbsp;
                                                        </div>
                                                        <div className={!selectedEvent['expanded'] ? 'course_type ellipsis_detail course_type_dim_m' : 'course_type course_type_dim_m'} > {selectedEvent.location}</div>
                                                    </div>


                                                </div>
                                                <hr />
                                                <div Style={"margin-top: 20px;"}>
                                                    <div className='flex'>
                                                        <div className='flex vertical-align card_column'>
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar margin-icon-card" viewBox="0 0 16 16">
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                </svg>
                                                            </div>
                                                            <div Style={"font-family: 'GucciSans-Book'"}>{formatData(selectedEvent.date)}</div>
                                                        </div>
                                                        <div className='flex vertical-align card_column'>
                                                            <div Style={"font-family: 'GucciSans-Book'"}>{selectedEvent.status}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedEvent.location && selectedEvent.location.length > 45
                                                    ? (!selectedEvent['expanded'] ?
                                                        <div Style={'position:absolute; right:10px;top:1px;z-index:20'} onClick={(ev) => opentext(ev, selectedEvent, selectedEvent, setSelectedEvent, 'detail')} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                            </svg>
                                                        </div>
                                                        :
                                                        <div Style={'position:absolute; right:10px;top:1px;z-index:20'} onClick={(ev) => closetext(ev, selectedEvent, selectedEvent, setSelectedEvent, 'detail')} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                            </svg></div>
                                                    ) : ''}
                                                <hr Style={"margin-top: 20px;"} />
                                                <div>
                                                    {selectedEvent.description ?
                                                        <React.Fragment>

                                                            <div className='label_text' Style={'margin-bottom:1px'}>
                                                                Description:
                                                            </div>
                                                            {selectedEvent.description}
                                                        </React.Fragment>
                                                        : ''}
                                                </div>
                                                <br />
                                                <div>
                                                    {selectedEvent.useful_links && selectedEvent.useful_links.length > 0 ? links : ''}
                                                </div>
                                                <br />
                                                {selectedEvent.materials && selectedEvent.materials.length > 0 ? <div Style={"font-family: 'GucciSans-Bold'"}>Materials</div> : ''}
                                                <div className='materials_container'>
                                                    {selectedEvent.materials && selectedEvent.materials.length > 0 ? materials : ''}
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>)
                                : <React.Fragment>
                                    <div className='calendar_list_button_container'>
                                        <div className='flex ' Style="justify-content: center; text-align: -webkit-center; margin-top: 20px;">
                                            <div className={selectedItem === 'calendar' ? 'pointer calendar_button_selected border_left' : 'pointer calendar_button border_left'} onClick={() => changeSelection('calendar')}>
                                                Calendar
                                            </div>
                                            <div className={selectedItem === 'list' ? 'pointer calendar_button_selected border_right' : 'pointer calendar_button border_right'} onClick={() => changeSelection('list')}>
                                                List
                                            </div>
                                        </div>
                                    </div>
                                    {selectedItem === 'calendar' ?
                                        <div>
                                            <div className='calendar_card'>
                                                <Calendar
                                                    localizer={localizer}
                                                    components={{
                                                        toolbar: props => (<CustomToolbar {...props} localizer={localizer} allEventsCount={allEventsCount} changeMonth={changeMonth} />),
                                                        month: {
                                                            dateHeader: ({ date, label }) => {
                                                                let highlightDate = 0
                                                                let ev = []
                                                                return (
                                                                    <React.Fragment>
                                                                        {allEvents.map((event) => {
                                                                            if (getData(date) === getData(event.start)) {
                                                                                highlightDate += 1
                                                                                ev.push(event)
                                                                            }
                                                                        })}
                                                                        {highlightDate == 1 ?
                                                                            <button type="button" class={date.setHours(0, 0, 0, 0) === new Date(selectedDate).setHours(0, 0, 0, 0) ? "rbc-button-link event_day selected_day_calendar" : "rbc-button-link event_day"} Style={"background-color: " + ev[0].color} onClick={() => selectDay(ev[0])} role="cell">{label}</button>
                                                                            : (highlightDate > 1 ? getMixButton(date, label, ev) :
                                                                                <button type="button" class={date.setHours(0, 0, 0, 0) === new Date(selectedDate).setHours(0, 0, 0, 0) ? "rbc-button-link selected_day_calendar" : "rbc-button-link"} role="cell" onClick={() => selectEmptyDay(date)}>{label}</button>
                                                                            )}

                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    eventPropGetter={eventPropGetter}
                                                    defaultDate={selectedDate}

                                                    formats={{ weekdayFormat: 'ddd' }}
                                                    events={allEvents}
                                                    startAccessor="start"
                                                    endAccessor="end"
                                                    style={[]}
                                                    selectable={false}
                                                    allDayAccessor={true}
                                                    onView={'month'}
                                                    views={['month']}
                                                    min={new Date(2017, 10, 0, 8, 0, 0)}
                                                    max={new Date(2017, 10, 0, 20, 0, 0)}
                                                />

                                            </div>
                                            <div className='calendar_list_container'>
                                                <div id="date_string_calendar_list">{dateString}</div>
                                                <br />
                                                <div className='calendar_list'>
                                                    {events_list}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='event_cards_list' Style={'padding-bottom: 100px;'}>
                                            {list_has_prev ? <div className='pointer' onClick={() => loadMoreList('prev')}><div className='loadMore_button'>Load Previous</div>
                                            </div> : ''}

                                            <div className='event_cards_container'>
                                                {events_card_list}
                                            </div>

                                            {list_has_more ? <div className='pointer' onClick={() => loadMoreList('next')}><div className='loadMore_button'>Load Next</div>
                                            </div> : ''}
                                        </div>
                                    }
                                </React.Fragment>))
                }
            </div >

            <Dialog
                open={props.filtersTab}
                onClose={() => props.setFiltersTab(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="m"
                PaperProps={{
                    style: {
                        height: '85h',
                        width: window.innerWidth < 1000 ? '85%' : (window.innerWidth < 1300 ? '50%' : '35%'),
                        'max-width': '85%',
                        'min-height': window.innerWidth < 900 ? '65vh' : '75vh',
                        'max-height': window.innerWidth < 900 ? '65vh' : '85vh',
                        'border-top-left-radius': '20px',
                        'border-top-right-radius': '20px',
                        'border-bottom-left-radius': window.innerWidth < 900 ? '0px' : '20px',
                        'border-bottom-right-radius': window.innerWidth < 900 ? '0px' : '20px',

                        animation: window.innerWidth < 900 ? 'bottom_to_top 1s forwards' : 'none',
                        position: window.innerWidth < 900 ? 'absolute' : 'relative',
                        bottom: window.innerWidth < 900 ? '0' : 'none',
                        margin: window.innerWidth < 900 ? '0' : 'auto'
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" Style={window.innerWidth < 900 ? 'position: fixed;width:calc(85% - 66px);    background-color: white;    z-index: 5; border-top-left-radius: 20px;' : ''}>
                    <Typography className="bold" variant="h5" >
                        Filters:
                    </Typography>
                    <div className="close_filters pointer" onClick={() => props.setFiltersTab(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                    </div></DialogTitle>
                <DialogContent>

                    <div className="w-100" Style={' width: 100%; position: absolute;left: 0;   top: 0;'}>
                        <div className="mobile_filter_container">

                            <h2 Style={'float: left;    margin-left: 8%;'}> </h2>
                            {!loadingFilters ? <React.Fragment>  <div className='filter_block' Style={window.innerWidth > 900 ? '   margin-top: 15%;' : 'margin-top:2%'}>
                                <label className='bold  '>Channels:</label>
                                {props.filters && props.filters.channels && props.filters.channels.length > 0 ? <Multiselect
                                    options={props.filters.channels} // Options to display in the dropdown
                                    selectedValues={selectedChannels} // Preselected value to persist in dropdown
                                    onSelect={(e) => selectFilter(e, 'channels')} // Function will trigger on select event
                                    onRemove={(e) => selectFilter(e, 'channels')} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    showCheckbox={true}
                                    avoidHighlightFirstOption={true}
                                    style={multiselectStyle} /> : ''}
                            </div>
                                <div className='filter_block' Style={'   margin-top:5%;'}>
                                    <label className='bold  '>Targets:</label>
                                    {props.filters && props.filters.targets && props.filters.targets.length > 0 ? <Multiselect
                                        options={props.filters.targets} // Options to display in the dropdown
                                        selectedValues={selectedTargets} // Preselected value to persist in dropdown
                                        onSelect={(e) => selectFilter(e, 'targets')} // Function will trigger on select event
                                        onRemove={(e) => selectFilter(e, 'targets')} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                        showCheckbox={true}
                                        avoidHighlightFirstOption={true}
                                        style={multiselectStyle} /> : ''}
                                </div>
                            </React.Fragment> : <div className="loader-cont">
                                <div className="loader1"></div>
                            </div>}
                            <div className='mt-50 mr-50'>


                            </div>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions Style={window.innerWidth < 900 ? ' position: fixed;    width: calc(85% - 66px);    background-color: white;    z-index: 5; bottom:0; ' : ''}>
                    <Button color="error" onClick={() => clear()} className="dialog_cancel_button">
                        Clear
                    </Button>
                    <Button className="dialog_close_button" onClick={() => filter()} disabled={confirmDisabled}>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={() => setModalStatus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography className="gucci_text_bold" variant="h5" >
                        Share event
                    </Typography></DialogTitle>
                <DialogContent>

                    <div Style={'min-height:50vh'}>
                        <div className='gucci_text'>  Share the event to a member of your team </div>
                        <br />
                        <MultiSelect
                            id='select'
                            options={usersList && usersList.length > 0 ? usersList : []}
                            value={usersListSelected}
                            onChange={setUsersListSelected}
                            labelledBy="Select"
                            valueRenderer={() => {
                                return usersListSelected.length > 0 ? usersListSelected.length + ' Selected'
                                    : (usersListSelected.length > 0 ? usersListSelected.map(({ full_name }, index) => index < usersListSelected.length && index != 0 ? ',' + full_name : full_name) : "Select");
                            }}
                            isCreatable={false}
                            hasSelectAll={true}
                            disableSearch={true}
                            shouldToggleOnHover={false}
                        />

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => cancel()} className="dialog_cancel_button">
                        Cancel
                    </Button>
                    <Button className="dialog_close_button" onClick={() => suggest()} disabled={confirmDisabled}>
                        Confirm
                    </Button>

                </DialogActions>
            </Dialog>


            <Dialog
                open={openFollowerModal}
                onClose={() => setFollowerModalStatus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography className="gucci_text_bold" variant="h5" >
                        Followers
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div Style={'min-height:50vh'}>
                        <div>
                            <div className='gucci_text'>Team member that are following this event</div>
                        </div>
                        <br />
                        <div className="follower_list">
                            {selectedEvent.wishlist_users && selectedEvent.wishlist_users.length > 0 ?
                                selectedEvent.wishlist_users.map((u) =>
                                    <div className='flex' Style={'font-size: 14px; align-items: center;'}>
                                        <div Style={'margin-right: 10px;'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-heart" viewBox="0 0 16 16">
                                                <path d="M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276Z" />
                                            </svg>
                                        </div>
                                        <div>{u.full_name} ({u.email})</div>
                                    </div>
                                )
                                :
                                "No follower"}
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setFollowerModalStatus(false)} className="dialog_cancel_button">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={qrTab}
                onClose={() => setQrTab(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                PaperProps={{
                    style: {
                        'min-height': '50vh'
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" Style={window.innerWidth < 900 ? 'width: calc(80% - 66px);    background-color: white;    z-index: 5; ' : ''}>
                    <Typography className="bold" variant="h5" >
                        Scan Qr Code:
                    </Typography>
                    <div className="close_filters pointer" onClick={() => setQrTab(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                    </div></DialogTitle>
                <DialogContent Style={'text-align:center; margin-top: 35px;'}>

                    <QRCode value={window.location.origin + (selectedEvent.has_survey ? '/survey/' : selectedEvent.tracked ? '/track/' : '/') + selectedEvent.id + '/'} />
                    <div className='flex  copy_button_container  pointer ' onClick={() => copyQr()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy share_ico" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                        </svg>

                        <div> COPY LINK</div>
                    </div>
                </DialogContent>

            </Dialog>
        </div >

    )
}